import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'

import { LinearProgress } from '@material-ui/core'

import { useLazyMiddleware, useAuthMiddleware } from '../../hooks'

const RouteWrapper = ({ children, permission, authorize, routeEnabled }) => {
  const lazyMiddleware = useLazyMiddleware({ fallback: <LinearProgress /> })
  const authMiddleware = useAuthMiddleware({ authorize, routeEnabled, requirements: { permissions: permission } })

  const composedMiddlewares = useMemo(() => {
    const middlewares = [authMiddleware, lazyMiddleware]
    return compose(...middlewares)
  }, [authMiddleware, lazyMiddleware])

  return composedMiddlewares(children)
}

RouteWrapper.defaultProps = {
  children: null,
  permission: null,
  authorize: false,
  routeEnabled: true,
}

RouteWrapper.propTypes = {
  children: PropTypes.node,
  permission: PropTypes.number,
  authorize: PropTypes.bool,
  routeEnabled: PropTypes.bool,
}

export default RouteWrapper
