import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RestoreOutlined, TuneOutlined } from '@mui/icons-material'
import { Box, Button, Collapse, Divider, Grid, IconButton, Stack, Tooltip, Typography } from '@mui/material'

import { useExpandable } from '@tabeeb/shared/utils/hooks'
import { sessionTitleFormatter } from '@tabeeb/shared/utils/text'
import AdvancedFiltersButton from '@tabeeb/modules/sncFilters/components/AdvancedFiltersButton'

import SearchInput from '../SearchInput'

import LayoutSelector from '../LayoutSelector'
import SortingColumnSelector from '../SortingColumnSelector'
import Selection from '../Selection'

import CreationDateFilter from './CreationDateFilter'
import SessionStatusFilter from './SessionStatusFilter'
import ReviewStatusFilter from './ReviewStatusFilter'
import ChatStatusFilter from './ChatStatusFilter'

import { resetFilters, setPageNumber } from '../../actions'
import { getIsFeatureEnabled } from '../../selectors'
import { Features } from '../../enums'
import {
  getIsFiltrationActive,
  getIsFiltrationByChatStatusEnabled,
  getIsFiltrationByDateEnabled,
  getIsFiltrationByReviewStatusEnabled,
  getIsFiltrationBySessionStatusEnabled,
} from '../../selectors/filtration'

const Filters = () => {
  const dispatch = useDispatch()

  const { expanded, onCollapse, onExpand } = useExpandable(false)

  const onApplyAdvancedFilters = useCallback(() => {
    dispatch(setPageNumber(1))
  }, [dispatch])

  const onResetFilters = useCallback(() => {
    dispatch(resetFilters())
  }, [dispatch])

  const isFiltrationByDateEnabled = useSelector(getIsFiltrationByDateEnabled)
  const isFiltrationBySessionStatusEnabled = useSelector(getIsFiltrationBySessionStatusEnabled)
  const isFiltrationByReviewStatusEnabled = useSelector(getIsFiltrationByReviewStatusEnabled)
  const isFiltrationByChatStatusEnabled = useSelector(getIsFiltrationByChatStatusEnabled)

  const isAnyFiltrationActive = useSelector(getIsFiltrationActive)
  const isAnyFiltrationEnabled =
    isFiltrationByDateEnabled || isFiltrationBySessionStatusEnabled || isFiltrationByReviewStatusEnabled

  const isAdvancedFiltersFeatureEnabled = useSelector((state) => getIsFeatureEnabled(state, Features.AdvancedFilters))

  return (
    <Box>
      <Box display='flex' justifyContent='space-between' alignItems='flex-start' mt={2} mb={2}>
        <Box display='flex' alignItems='center' flexGrow={1}>
          <SearchInput
            endAdornment={
              <>
                {isAnyFiltrationEnabled && (
                  <Tooltip title={expanded ? 'Hide filters' : 'Show filters'}>
                    <IconButton size='small' onClick={expanded ? onCollapse : onExpand}>
                      <TuneOutlined color={isAnyFiltrationActive ? 'primary' : 'action'} />
                    </IconButton>
                  </Tooltip>
                )}
                {isAdvancedFiltersFeatureEnabled && <AdvancedFiltersButton onApply={onApplyAdvancedFilters} />}
              </>
            }
          />

          <Selection />
        </Box>
        <Stack direction='row' spacing={1.5}>
          <SortingColumnSelector />
          {isFiltrationByChatStatusEnabled && <ChatStatusFilter />}
          <LayoutSelector />
        </Stack>
      </Box>
      <Collapse in={isAnyFiltrationEnabled && expanded} unmountOnExit>
        <Box position='relative'>
          <Divider />
          <Grid container spacing={4} paddingY={2}>
            {isFiltrationByDateEnabled && (
              <Grid item xs={12} sm={12} md={4}>
                <Typography variant='body1' marginBottom={1} fontWeight={600} noWrap>
                  Date
                </Typography>
                <CreationDateFilter />
              </Grid>
            )}
            {isFiltrationBySessionStatusEnabled && (
              <Grid item xs={12} sm={12} md={4}>
                <Typography variant='body1' marginBottom={1} fontWeight={600} noWrap>
                  {sessionTitleFormatter.format('Session status')}
                </Typography>
                <SessionStatusFilter variant='select' />
              </Grid>
            )}
            {isFiltrationByReviewStatusEnabled && (
              <Grid item xs={12} sm={12} md={4}>
                <Typography variant='body1' marginBottom={1} fontWeight={600} noWrap>
                  Review status
                </Typography>
                <ReviewStatusFilter variant='select' />
              </Grid>
            )}
          </Grid>

          <Tooltip title='Reset filters'>
            <Button
              sx={{ position: 'absolute', color: 'inherit', top: (theme) => theme.spacing(1), right: 0 }}
              startIcon={<RestoreOutlined color='pagePrimaryAction' />}
              onClick={onResetFilters}
            >
              Reset all
            </Button>
          </Tooltip>
          <Divider />
        </Box>
      </Collapse>
    </Box>
  )
}

export default Filters
