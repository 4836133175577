import { useCallback, useState } from 'react'
import { Select, MenuItem, SelectChangeEvent, ListSubheader } from '@mui/material'
import { SearchTextField, useDebouncedValue } from '@tabeeb/uikit'
import { FormControl } from '@tabeeb/modules/createFormPage/types'
import { FormAnswer } from '../../types'

interface FormDropdownProps {
  disabled: boolean
  control: FormControl
  answer: FormAnswer
  handleChange: (event: SelectChangeEvent<number>) => void
}

const FormDropdown: React.FC<FormDropdownProps> = ({ disabled, control, answer, handleChange }) => {
  const [searchText, setSearchText] = useState('')
  const debouncedSearchValue = useDebouncedValue(searchText, 350)
  const { Options, Tooltip } = control
  const selectedOptionId = answer.selectedOptionsIds ? answer.selectedOptionsIds[0] : ''
  const selectedOption = Options.find((option) => option.Id === selectedOptionId)

  const matchingOptions = Options.filter((option) =>
    option.Value.toLowerCase().includes(debouncedSearchValue.toLowerCase())
  )

  const nonMatchingOptions = Options.filter(
    (option) => !option.Value.toLowerCase().includes(debouncedSearchValue.toLowerCase())
  )

  const optionsList = [...matchingOptions, ...nonMatchingOptions]

  const handleSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value)
  }, [])

  const handleKeyDown = useCallback((e: React.KeyboardEvent) => {
    if (e.key !== 'Escape') {
      e.stopPropagation()
    }
  }, [])

  return (
    <Select
      fullWidth
      title={Tooltip}
      value={selectedOptionId || ''}
      onChange={handleChange}
      disabled={disabled}
      variant='outlined'
      size='small'
      MenuProps={{ autoFocus: false }}
      onClose={() => setSearchText('')}
      renderValue={() => selectedOption?.Value || ''}
      SelectDisplayProps={{
        style: { padding: '4px 8px' },
      }}
    >
      <ListSubheader>
        <SearchTextField
          size='small'
          autoFocus
          placeholder='Search...'
          fullWidth
          onChange={handleSearchChange}
          onKeyDown={handleKeyDown}
        />
      </ListSubheader>
      {optionsList.map((option) => (
        <MenuItem
          value={option.Id}
          key={option.Id}
          sx={{
            opacity: matchingOptions.includes(option) ? 1 : 0.3,
          }}
        >
          {option.Value}
        </MenuItem>
      ))}
    </Select>
  )
}

export default FormDropdown
