import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'

import selector from '@tabeeb/shared/utils/selector'
import domainService from '@tabeeb/services/domainService'

export function configureConnection({ apiUrl, hubName }) {
  const connection = new HubConnectionBuilder()
    .withUrl(`${apiUrl}${hubName}`, {
      accessTokenFactory: () => selector.getAccessToken(),
      headers: { 'X-Tenant-Subdomain': domainService.subdomain },
    })
    .withAutomaticReconnect()
    .configureLogging(LogLevel.Warning)
    .build()

  return connection
}
