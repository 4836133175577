import { Component } from 'react'
import { Provider, ReactReduxContext } from 'react-redux'

import { PersistGate } from 'redux-persist/integration/react'

import { ThemeProvider } from '@mui/material'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

import { ConnectedRouter } from 'connected-react-router'

import { tabeebTheme, modernV5, ToastsProvider } from '@tabeeb/uikit'

import { HelmetProvider } from 'react-helmet-async'

import { store, persistor, history } from '@tabeeb/store'

import { QueryClientProvider } from '@tanstack/react-query'
import { queryClient } from '@tabeeb/services/queryClient'
import { onAppMounted } from '../../state/actions/appActions'

import UI from './App'

class App extends Component {
  componentDidMount() {
    store.dispatch(onAppMounted())
  }

  render() {
    return (
      <MuiThemeProvider theme={tabeebTheme}>
        <ThemeProvider theme={modernV5}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Provider store={store} context={ReactReduxContext}>
              <PersistGate persistor={persistor}>
                <ConnectedRouter history={history} context={ReactReduxContext}>
                  <HelmetProvider>
                    <ToastsProvider>
                      <QueryClientProvider client={queryClient}>
                        <UI />
                      </QueryClientProvider>
                    </ToastsProvider>
                  </HelmetProvider>
                </ConnectedRouter>
              </PersistGate>
            </Provider>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </MuiThemeProvider>
    )
  }
}

export default App
