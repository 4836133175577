import React, { useCallback, useEffect, useMemo, useState } from 'react'

export interface UseTablePaginationHookProps {
  total?: number
  pageSize?: number
  initialPage?: number
}

export default ({ total = -1, pageSize = 25, initialPage = 0 }: UseTablePaginationHookProps = {}) => {
  const [{ size, current }, setState] = useState<{ size: number; current: number }>({
    size: pageSize,
    current: initialPage,
  })

  useEffect(() => {
    if (total === -1) {
      return
    }

    if (Math.ceil(total / size) < current && current > 0) {
      setState((prevState) => ({ ...prevState, current: 0 }))
    }
  }, [total, current, size])

  const onPageChange = useCallback((_event: React.MouseEvent, page: number) => {
    setState((prevState) => ({ ...prevState, current: page }))
  }, [])

  const onRowsPerPageChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const newPageSize = event.target.value as unknown as number
    setState((prevState) => ({ ...prevState, size: newPageSize, current: 0 }))
  }, [])

  const pagesCount = useMemo(() => {
    if (total >= 0) {
      return Math.ceil(total / size)
    }

    return -1
  }, [size, total])

  return {
    current,
    pagesCount,
    size,
    onPageChange,
    onRowsPerPageChange,
  }
}
