import { xorWith, isEqual } from 'lodash'

export const addQueriesToUrl = (keyValues, pathName, search, deletedKeys = []) => {
  const searchParams = new URLSearchParams(search)

  if (deletedKeys.length > 0) {
    for (let i = 0; i < deletedKeys.length; i++) {
      if (searchParams.has(deletedKeys[i])) {
        searchParams.delete(deletedKeys[i])
      }
    }
  }

  if (keyValues.length > 0) {
    for (let i = 0; i < keyValues.length; i++) {
      if (searchParams.has(keyValues[i].key)) {
        if (Array.isArray(keyValues[i].value)) {
          searchParams.delete(keyValues[i].key)
          keyValues[i].value.forEach((arrayEl) => searchParams.append(keyValues[i].key, arrayEl))
        } else {
          searchParams.set(keyValues[i].key, keyValues[i].value)
        }
      } else if (Array.isArray(keyValues[i].value)) {
        keyValues[i].value.forEach((arrayEl) => searchParams.append(keyValues[i].key, arrayEl))
      } else {
        searchParams.append(keyValues[i].key, keyValues[i].value)
      }
    }
  }

  return {
    pathname: pathName,
    search: searchParams.toString(),
  }
}

export const conditionalPropType = (condition) => {
  if (typeof condition !== 'function') {
    return new Error("Wrong argument type 'condition' supplied to 'conditionalPropType'")
  }

  return (props, propName, componentName) => {
    if (condition(props, propName, componentName)) {
      return new Error(`Invalid prop '${propName}' '${props[propName]}' supplied to '${componentName}'.`)
    }
  }
}

export const isLodashEqualForArray = (array1, array2) => {
  const sameSize = array1.length === array2.length
  return sameSize && xorWith(array1, array2, isEqual).length === 0
}

export const isLegacyUrl = (routerLocation) => {
  return routerLocation.hash.startsWith('#/')
}

export const redirectToNewLinkFormat = () => {
  const newUrl = window.location.href.replace('#/', '')
  window.location.replace(newUrl)
}

export function removeEmptyFromJson(obj) {
  const clean = Object.fromEntries(
    Object.entries(obj)
      .map(([key, value]) => [key, value === Object(value) ? removeEmptyFromJson(value) : value])
      .filter(([_, value]) => value != null && (value !== Object(value) || Object.keys(value).length))
  )
  return Array.isArray(obj) ? Object.values(clean) : clean
}
