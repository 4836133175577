import { Paper, styled } from '@mui/material'

export const Container = styled(Paper)(({ theme }) => ({
  background: theme.palette.background.session,
  color: theme.palette.text.folder,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'relative',
  padding: theme.spacing(),
  paddingLeft: theme.spacing(2),
  height: 50,

  '&:hover': {
    boxShadow: theme.shadows[4],
  },
}))
