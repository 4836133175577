import { useCallback, useEffect, useState } from 'react'

export interface UsePaginationHookProps {
  total?: number
  pageSize?: number
  initialPage?: number
}

export default ({ total = 0, pageSize = 25, initialPage = 1 }: UsePaginationHookProps = {}) => {
  const [{ size, current }, setState] = useState<{ size: number; current: number }>({
    size: pageSize,
    current: initialPage,
  })

  useEffect(() => {
    if (Math.ceil(total / size) < current && current > 1) {
      setState((prevState) => ({ ...prevState, current: 1 }))
    }
  }, [total, current, size])

  const onPageNumberChange = useCallback((newPageNumber: number) => {
    setState((prevState) => ({ ...prevState, current: newPageNumber }))
  }, [])

  const onPageSizeChange = useCallback((newPageSize: number) => {
    setState((prevState) => ({ ...prevState, size: newPageSize, current: 1 }))
  }, [])

  return {
    current,
    pages: Math.ceil(total / size),
    size,
    onPageNumberChange,
    onPageSizeChange,
  }
}
