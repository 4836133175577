import { memo } from 'react'

import { MarkEmailUnreadOutlined } from '@mui/icons-material'
import { ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material'

import { useDispatch, useSelector } from '@tabeeb/store'

import { sessionTitleFormatter } from '@tabeeb/modules/shared/utils/text'
import { updateChatStatusFilter } from '@tabeeb/modules/sessionsPage/actions'
import { getChatStatusFilter } from '@tabeeb/modules/sessionsPage/selectors/filtration'
import { ChatStatus } from '@tabeeb/modules/sessionsPage/enums'

const ChatStatusFilter = () => {
  const dispatch = useDispatch()

  const unreadOnlyFiltrationEnabled = useSelector(getChatStatusFilter) === ChatStatus.UnreadOnly

  return (
    <ToggleButtonGroup
      exclusive
      color='pagePrimaryAction'
      sx={{ background: (theme) => theme.palette.background.paper }}
      onChange={() =>
        dispatch(updateChatStatusFilter(unreadOnlyFiltrationEnabled ? ChatStatus.All : ChatStatus.UnreadOnly))
      }
    >
      <Tooltip
        title={
          unreadOnlyFiltrationEnabled
            ? sessionTitleFormatter.format('Display all sessions')
            : sessionTitleFormatter.format('Display unread sessions only')
        }
      >
        <ToggleButton value={ChatStatus.UnreadOnly} selected={unreadOnlyFiltrationEnabled}>
          <MarkEmailUnreadOutlined />
        </ToggleButton>
      </Tooltip>
    </ToggleButtonGroup>
  )
}

export default memo(ChatStatusFilter)
