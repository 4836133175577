import { put, takeLatest, select, all } from 'redux-saga/effects'

import { getIsSncFiltersEnabled, getSncFilterModel } from '@tabeeb/modules/sncFilters/selectors'

import { hasTenantPermission } from '@tabeeb/modules/permissions/selectors'
import { ContentSharingType, TenantPermission } from '@tabeeb/enums'
import { getCurrentUserId, getMe } from '@tabeeb/modules/account/selectors'
import { acceptNotificationRequest } from '@tabeeb/modules/notificationsPage/actions'
import { goToContent } from '@tabeeb/modules/shared/content/actions'
import { getTenantContentReviews, initiateJoinContentReview } from '@tabeeb/modules/contentReviews/actions'

import { callApiAsync } from '@tabeeb/shared/utils/requests'

import * as rawActions from '../actions'
import * as notificationActions from '../../notification/actions'
import getErrorMessageFromResponse from '../../shared/utils/getErrorMessageFromResponse'
import {
  getDateFilter,
  getIsFiltrationByReviewStatusEnabled,
  getIsFiltrationBySessionStatusEnabled,
  getReviewStatusFilter,
  getSearchQuery,
  getSessionStatusFilter,
  getChatStatusFilter,
  getIsFiltrationByChatStatusEnabled,
} from '../selectors/filtration'
import { getPaginationPageNumber, getPaginationPageSize } from '../selectors/pagination'
import { getIsSortingByColumnEnabled, getSortingColumn, getSortingOrder } from '../selectors/sorting'
import { getCurrentFolderId, getIsFeatureEnabled, getIsTrashOpen, getMode } from '../selectors'
import { ChatStatus, DialogTypes, Features } from '../enums'

function* loadSessionsList(action) {
  const withNoReload = false

  if (!withNoReload) {
    yield put(rawActions.setSessionListLoading())
  }

  const pageNumber = yield select(getPaginationPageNumber)
  const pageSize = yield select(getPaginationPageSize)
  const reviewStatusFilter = yield select(getReviewStatusFilter)
  const reviewStatusFilterEnabled = yield select(getIsFiltrationByReviewStatusEnabled)
  const searchText = yield select(getSearchQuery)
  const sessionStatusFilter = yield select(getSessionStatusFilter)
  const chatStatusFilter = yield select(getChatStatusFilter)
  const chatStatusFilterEnabled = yield select(getIsFiltrationByChatStatusEnabled)
  const sessionStatusFilterEnabled = yield select(getIsFiltrationBySessionStatusEnabled)
  const sortingColumn = yield select(getSortingColumn)
  const sortingOrder = yield select(getSortingOrder)

  const isSortingColumnEnabled = yield select((state) => getIsSortingByColumnEnabled(state, sortingColumn))
  if (!isSortingColumnEnabled) {
    return
  }

  const { startDate, endDate } = yield select(getDateFilter)

  const currentFolderId = yield select(getCurrentFolderId)
  const isTrashOpen = yield select(getIsTrashOpen)
  const mode = yield select(getMode)
  const sncFilterEnabled = yield select(getIsSncFiltersEnabled)
  const sncFiltersFeatureEnabled = yield select((state) => getIsFeatureEnabled(state, Features.AdvancedFilters))
  const useSncFilters = sncFilterEnabled && sncFiltersFeatureEnabled

  let sncFiltersModel = []
  if (useSncFilters) {
    sncFiltersModel = yield select(getSncFilterModel)
  }

  const showWithUnreadMessagesOnly = chatStatusFilterEnabled ? chatStatusFilter === ChatStatus.UnreadOnly : false

  const data = {
    skip: (pageNumber - 1) * pageSize,
    take: pageSize,
    displayType: mode,
    searchQuery: {
      Sorting: {
        Column: sortingColumn,
        Order: sortingOrder,
      },
      Filtering: {
        GeneralFilter: searchText,
        ReviewStatus: reviewStatusFilterEnabled ? reviewStatusFilter : [],
        SessionStatus: sessionStatusFilterEnabled ? sessionStatusFilter : [],
        NamePartsQuestionAnswers: sncFiltersModel,
        SncFiltersEnabled: useSncFilters,
        IsShowWithUnreadMessagesOnly: showWithUnreadMessagesOnly,
        CreatedOn: {
          StartDate: startDate,
          EndDate: endDate,
        },
      },
    },
    folderId: currentFolderId,
    isDeleted: isTrashOpen,
  }

  yield put(rawActions.getSessions.request(data))
}

function* getSessionsFailed({ response }) {
  const message = getErrorMessageFromResponse(response?.data)
  yield put(notificationActions.onAddErrorNotification({ message }))
}

function* openSession({ payload: { session, target } }) {
  const currentUser = yield select(getMe)

  if (session.TenantId === currentUser.TenantId) {
    yield put(goToContent({ id: session.Id, target }))

    return
  }

  try {
    const {
      response: { data: reviewsList },
    } = yield callApiAsync(getTenantContentReviews.request(session.Id))
    const currentUserId = yield select(getCurrentUserId)

    const acceptedReviews = reviewsList.filter((r) => r.IsActive)
    const isAlreadyReviewer = acceptedReviews.some(
      (r) => r.ReviewersIds.includes(currentUserId) || r.ReviewerId === currentUserId
    )
    if (isAlreadyReviewer) {
      yield put(goToContent({ id: session.Id, target }))

      return
    }

    const reviewToAccept = reviewsList.find((r) => r.ReviewerId === currentUserId && !r.IsActive)
    if (reviewToAccept) {
      yield put(
        acceptNotificationRequest({
          ContentSharingId: reviewToAccept.Id,
          ContentSharingType: ContentSharingType.ForReview,
          ContentId: reviewToAccept.ContentId,
        })
      )

      return
    }

    const hasJoinContentReviewPermission = yield select((state) =>
      hasTenantPermission(state, TenantPermission.JoinContentReview)
    )
    if (hasJoinContentReviewPermission) {
      if (reviewsList.length === 1) {
        yield put(initiateJoinContentReview(reviewsList[0]))
      } else if (reviewsList.length > 1) {
        yield put(
          rawActions.openSessionsPageDialog({ dialogType: DialogTypes.JoinSessionReview, dialogPayload: { session } })
        )
      }
    }
  } catch {
    // ignored
  }
}

function* sessionsList() {
  yield all([
    takeLatest(
      [
        rawActions.loadSessionsList,
        rawActions.clearCurrentFolder,
        // pagination
        rawActions.setPageNumber,
        rawActions.setPageSize,
        // filters
        rawActions.setSorting,
        rawActions.updateDateFilter,
        rawActions.updateSessionStatusFilter,
        rawActions.updateReviewStatusFilter,
        rawActions.updateChatStatusFilter,
        rawActions.resetFilters,
      ],
      loadSessionsList
    ),
    takeLatest(rawActions.openSession, openSession),
    takeLatest(rawActions.getSessions.failed, getSessionsFailed),
  ])
}

export default sessionsList
