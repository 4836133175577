import { useState, useCallback } from 'react'
import { ModalProps } from '@mui/material/Modal'

export default function useDialogState(): [boolean, () => void, () => void] {
  const [open, setIsOpen] = useState<ModalProps['open']>(false)

  const onOpen = useCallback(() => {
    setIsOpen(true)
  }, [])

  const onClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  return [open, onOpen, onClose]
}
