import { Dispatch, SetStateAction, useState } from 'react'
import { useDebouncedValue } from '@tabeeb/uikit'

type DebouncedState<TState> = [state: TState, debouncedState: TState, setState: Dispatch<SetStateAction<TState>>]

export default function useDebouncedState<TState>(value: TState, delay: number = 300): DebouncedState<TState> {
  const [state, setState] = useState<TState>(value)

  const debouncedState = useDebouncedValue(state, delay)

  return [state, debouncedState, setState]
}
