import { useCallback, useState } from 'react'
import { useDispatch } from '@tabeeb/store'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  NotificationsCategory,
  NotificationTypeEnum,
  UserNotificationSettingsResponse,
} from '@tabeeb/contracts/microservices/swagger-notifications-service'
import {
  disableNotificationSettingRequest,
  enableNotificationSettingRequest,
  getNotificationSettingsRequest,
  AccountQueryKeys,
} from '@tabeeb/modules/account/requests'
import { onAddErrorNotification } from '@tabeeb/modules/notification/actions'

const useNotificationSettings = () => {
  const [settingsInProcessing, setSettingsInProcessing] = useState<NotificationTypeEnum[]>([])
  const queryClient = useQueryClient()
  const dispatch = useDispatch()

  const userNotificationSettingsQuery = useQuery<UserNotificationSettingsResponse, Error>({
    queryKey: [AccountQueryKeys.notificationSettings],
    queryFn: getNotificationSettingsRequest,
    staleTime: 20 * 1000, // 20 seconds
    refetchOnWindowFocus: true,
  })

  const updateCategorySetting = useCallback(
    (notificationCategory: NotificationsCategory, notificationType: NotificationTypeEnum, enabled: boolean) => {
      const notificationSettings = notificationCategory.notificationSettings?.map((notificationSetting) => {
        if (notificationSetting.type === notificationType) {
          return {
            ...notificationSetting,
            isEnabled: enabled,
          }
        }
        return notificationSetting
      })

      return { name: notificationCategory.name, notificationSettings }
    },
    []
  )

  const handleMutateError = useCallback(
    (notificationType: NotificationTypeEnum, requestedState: boolean) => {
      const message = `Failed to ${requestedState ? 'enable' : 'disable'} notification`

      dispatch(onAddErrorNotification({ message }))

      queryClient.setQueryData<UserNotificationSettingsResponse>([AccountQueryKeys.notificationSettings], (prev) => {
        if (!prev) {
          return prev
        }
        return {
          ...prev,
          notificationCategories: prev.notificationCategories?.map((notificationCategory) =>
            updateCategorySetting(notificationCategory, notificationType, !requestedState)
          ),
        }
      })
    },
    [dispatch, queryClient, updateCategorySetting]
  )

  const handleMutate = useCallback(
    (notificationType: NotificationTypeEnum, enabled: boolean) => {
      setSettingsInProcessing((prev) => [...prev, notificationType])

      queryClient.setQueryData<UserNotificationSettingsResponse>([AccountQueryKeys.notificationSettings], (prev) => {
        if (!prev) {
          return prev
        }
        return {
          ...prev,
          notificationCategories: prev.notificationCategories?.map((notificationCategory) =>
            updateCategorySetting(notificationCategory, notificationType, enabled)
          ),
        }
      })
    },
    [queryClient, updateCategorySetting]
  )

  const enableUserNotificationMutation = useMutation({
    mutationFn: enableNotificationSettingRequest,
    onError: (_error, request) => {
      handleMutateError(request.notificationType, true)
    },
    onSettled: (_data, _error, request) => {
      setSettingsInProcessing((prev) => prev.filter((element) => element !== request.notificationType))
    },
    onMutate: (request) => {
      handleMutate(request.notificationType, true)
    },
  })

  const disableUserNotificationMutation = useMutation({
    mutationFn: disableNotificationSettingRequest,
    onError: (_error, request) => {
      handleMutateError(request.notificationType, false)
    },
    onSettled: (_data, _error, request) => {
      setSettingsInProcessing((prev) => prev.filter((element) => element !== request.notificationType))
    },
    onMutate: (request) => {
      handleMutate(request.notificationType, false)
    },
  })

  const onNotificationSettingChange = useCallback(
    (notificationType: NotificationTypeEnum, checked: boolean) => {
      if (checked) {
        enableUserNotificationMutation.mutate({ notificationType, tenantScoped: true })
      } else {
        disableUserNotificationMutation.mutate({ notificationType, tenantScoped: true })
      }
    },
    [disableUserNotificationMutation, enableUserNotificationMutation]
  )

  return {
    settingsInProcessing,
    notificationsCategories: userNotificationSettingsQuery.data?.notificationCategories || [],
    isLoading: userNotificationSettingsQuery.isLoading,
    isError: userNotificationSettingsQuery.isError,
    isFetching: userNotificationSettingsQuery.isFetching,
    onNotificationSettingChange,
  }
}

export default useNotificationSettings
