import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Build, MonetizationOn } from '@material-ui/icons'

import routes from '@tabeeb/routes'
import { TenantPermission } from '@tabeeb/enums'
import { permissionsSelectors } from '@tabeeb/modules/permissions'
import { appConfigStateSelectors } from '@tabeeb/modules/appConfigState'

import { getAvailableAnnouncementScopes } from '@tabeeb/modules/articles/selectors'
import { getShowCertificatesManagement } from '@tabeeb/modules/certificates/selectors'

import NavbarTab from '../NavbarTab'

const Settings = ({
  showDevices,
  showAnnouncements,
  showTenantSettings,
  showBillingSettings,
  showAllUsers,
  showMenuAI,
  isBillingEnabled,
  showContracts,
  showCertificates,
  showWorkflows,
  showSalesforceIntegration,
  ...rest
}) => {
  const subitems = []
  if (showMenuAI) {
    subitems.push({ label: 'AI', to: routes.aiModels })
  }

  if (showAnnouncements) {
    subitems.push({ label: 'Announcements', to: routes.announcements })
  }

  if (showBillingSettings) {
    subitems.push({
      label: 'Billing settings',
      to: routes.billingSettings,
      icon: <MonetizationOn />,
    })
  }

  if (showCertificates) {
    subitems.push({
      label: 'Certificates',
      to: routes.certificatesBase,
    })
  }

  if (showContracts) {
    subitems.push({ label: 'Contracts', to: routes.contractorsBase })
  }

  if (showDevices) {
    subitems.push({ label: 'Devices', to: routes.devices })
  }

  if (showTenantSettings) {
    subitems.push({ label: 'Tenant settings', to: routes.tenantConfig, icon: <Build /> })
  }

  if (showAllUsers) {
    subitems.push({ label: 'Users', to: routes.allUsers })
  }

  if (showWorkflows) {
    subitems.push({ label: 'Workflows', to: routes.workflows })
  }

  if (showSalesforceIntegration) {
    subitems.push({ label: 'Salesforce integration', to: routes.salesforce })
  }

  const props = {
    item: {
      label: 'Settings',
      subitems,
    },
    display: showBillingSettings || showTenantSettings,
    ...rest,
  }

  return <NavbarTab {...props} />
}

Settings.propTypes = {
  showTenantSettings: PropTypes.bool.isRequired,
  showBillingSettings: PropTypes.bool.isRequired,
  showAllUsers: PropTypes.bool.isRequired,
  showMenuAI: PropTypes.bool.isRequired,
  showAnnouncements: PropTypes.bool.isRequired,
  showDevices: PropTypes.bool.isRequired,
  showContracts: PropTypes.bool.isRequired,
  showCertificates: PropTypes.bool.isRequired,
  showWorkflows: PropTypes.bool.isRequired,
  showSalesforceIntegration: PropTypes.bool.isRequired,
  isBillingEnabled: PropTypes.bool.isRequired,
}

function mapStateToProps(state) {
  return {
    isBillingEnabled: appConfigStateSelectors.getIsBillingEnabled(state),
    showTenantSettings: permissionsSelectors.hasTenantPermission(state, TenantPermission.TenantSettings),
    showBillingSettings:
      permissionsSelectors.hasTenantPermission(state, TenantPermission.BillingSettings) ||
      permissionsSelectors.hasTenantPermission(state, TenantPermission.SessionApprovalSettings),
    showAllUsers: permissionsSelectors.hasTenantPermission(state, TenantPermission.AllUsersView),
    showMenuAI:
      appConfigStateSelectors.getShowMenuAI(state) &&
      permissionsSelectors.hasTenantPermission(state, TenantPermission.AISettings),
    showAnnouncements: getAvailableAnnouncementScopes(state).length > 0,
    showDevices: permissionsSelectors.hasTenantPermission(state, TenantPermission.Devices),
    showContracts:
      permissionsSelectors.hasTenantPermission(state, TenantPermission.ContractsManagement) &&
      (appConfigStateSelectors.getIsIncomingContractsEnabled(state) ||
        appConfigStateSelectors.getIsOutgoingContractsEnabled(state)),
    showCertificates: getShowCertificatesManagement(state),
    showWorkflows: permissionsSelectors.hasTenantPermission(state, TenantPermission.ManageWorkflows),
    showSalesforceIntegration: permissionsSelectors.hasTenantPermission(
      state,
      TenantPermission.ViewSalesforceIntegrationNodes
    ),
  }
}

export default connect(mapStateToProps)(Settings)
