import watchRTC from '@testrtc/watchrtc-sdk'

class WatchRTCHandler {
  init(options) {
    if (options?.watchRTCConfigParams?.rtcApiKey) {
      watchRTC.init({
        rtcApiKey: options.watchRTCConfigParams.rtcApiKey,
      })
      this.options = { ...options.watchRTCConfigParams }
    }
  }

  start(roomName, userName, { tenantId, userId }) {
    try {
      if (this.options) {
        this.options = {
          ...this.options,
          rtcRoomId: roomName,
          rtcPeerId: userName,
          keys: {
            CollaborateEnv: process.env.TABEEB_WATCHRTC_ENVIRONMENT_NAME,
            CollaborateClientType: 'web',
            CollaborateJistiBuild: process.env.TABEEB_JITSI_BUILD_VERSION,
            CollaborateBridge: window.config.hosts?.domain,
            CollaborateTenantID: tenantId,
            CollaborateClientID: userId,
          },
        }

        watchRTC.persistentEnd()
        watchRTC.setConfig(this.options)
      }
    } catch (error) {
      // ignored
    }
  }
}

export default new WatchRTCHandler()
