import { styled } from '@mui/material'

import { MarkEmailUnreadOutlined } from '@mui/icons-material'

export const BubbleContainer = styled('div')(({ theme }) => {
  const size = 32

  return {
    position: 'absolute',
    top: theme.spacing(-1.25),
    right: theme.spacing(-1.25),
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    backgroundColor: '#FF7F32',
    height: size,
    width: size,
  }
})

export const UnreadMessagesIcon = styled(MarkEmailUnreadOutlined)(() => ({
  marginRight: '-1px',
  color: '#FFFFFF',
}))
