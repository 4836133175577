const rootUri = 'reporting-service/api/reports/generated'

export const createReportsBySubdomainRequest = (data) => ({
  url: `${rootUri}/`,
  method: 'post',
  data,
})

export const getGeneratedReportsRequest = ({ subdomain, ...params }) => ({
  url: `${rootUri}/${subdomain}`,
  method: 'get',
  params,
})

export const getReportReceiversRequest = ({ skip, take, reportId } = { skip: 0, take: 50 }) => ({
  url: `${rootUri}/${reportId}/receivers`,
  method: 'get',
  params: {
    skip,
    take,
  },
})

export const getReportDownloadUrlRequest = (reportId) => ({
  url: `${rootUri}/${reportId}/download/url`,
  method: 'get',
})

export const revokeReportAccessRequest = ({ reportId, receiverId }) => ({
  url: `${rootUri}/${reportId}/revoke/${receiverId}`,
  method: 'post',
})

export const renewReportAccessRequest = ({ reportId, receiverId }) => ({
  url: `${rootUri}/${reportId}/renew/${receiverId}`,
  method: 'post',
})
