import { handleActions } from 'redux-actions'

import * as rawActions from '../../actions'
import { createOption } from '../../services/createOption'
import { ConditionTriggerTypes, ContentType, ControlType, FormControlType } from '../../../../Enums'

const defaultState = []

function _updateOrder(controlsList, control) {
  const updatedList = controlsList.map((cont) => {
    if (cont.TempId === control.TempId) {
      const updatedOptions = cont.Options.map((option, index) => {
        return { ...option, Order: index }
      })
      return { ...cont, Options: updatedOptions }
    }

    return cont
  })

  return updatedList
}

export default handleActions(
  {
    [rawActions.addControl](state, action) {
      return [...state, action.payload]
    },
    [rawActions.updateControlsList](state, action) {
      return [...action.payload]
    },
    [rawActions.deleteControl](state, { payload }) {
      return state.filter((control) => control.TempId !== payload.TempId)
    },
    [rawActions.saveControlName](state, { payload: { control, name } }) {
      return state.map((cont) => (cont.TempId === control.TempId ? { ...cont, Label: name } : cont))
    },
    [rawActions.saveControlTooltip](state, { payload: { control, tooltip } }) {
      return state.map((cont) => (cont.TempId === control.TempId ? { ...cont, Tooltip: tooltip } : cont))
    },
    [rawActions.saveInitialValue](state, { payload: { control, initialValue } }) {
      return state.map((cont) => (cont.TempId === control.TempId ? { ...cont, InitialValue: initialValue } : cont))
    },
    [rawActions.savePlaceholder](state, { payload: { control, placeholder } }) {
      return state.map((cont) => (cont.TempId === control.TempId ? { ...cont, Placeholder: placeholder } : cont))
    },
    [rawActions.saveIncrementValue](state, { payload: { control, incrementValue } }) {
      return state.map((cont) => (cont.TempId === control.TempId ? { ...cont, IncrementValue: incrementValue } : cont))
    },
    [rawActions.saveIncrementPeriod](state, { payload: { control, incrementPeriod } }) {
      return state.map((cont) =>
        cont.TempId === control.TempId ? { ...cont, IncrementPeriod: incrementPeriod } : cont
      )
    },
    [rawActions.setCountAiObjectsFlag](state, { payload: { control, countObjects } }) {
      return state.map((cont) => (cont.TempId === control.TempId ? { ...cont, CountAIObjects: countObjects } : cont))
    },
    [rawActions.setAiObjectId](state, { payload: { control, aiObjectId } }) {
      return state.map((cont) => (cont.TempId === control.TempId ? { ...cont, AIObjectId: aiObjectId } : cont))
    },
    [rawActions.saveCounterLimit](state, { payload: { control, counterLimit } }) {
      return state.map((cont) =>
        cont.TempId === control.TempId
          ? {
              ...cont,
              CounterLimit: counterLimit,
              Conditions: counterLimit ? cont.Conditions : [],
              IncrementValue: counterLimit ? cont.IncrementValue : null,
              IncrementPeriod: counterLimit ? cont.IncrementPeriod : null,
            }
          : cont
      )
    },
    [rawActions.setControlConditions](state, { payload: { control, conditions } }) {
      return state.map((cont) => (cont.TempId === control.TempId ? { ...cont, Conditions: conditions } : cont))
    },
    [rawActions.setOptionConditions](state, { payload: { control, option, conditions } }) {
      return state.map((cont) => {
        if (cont.TempId === control.TempId) {
          cont.Options.map((opt) => {
            if (opt.Id === option.Id) {
              opt.Conditions = conditions
            }
            return opt
          })
        }
        return cont
      })
    },
    [rawActions.addControlCondition](state, { payload: { control, condition } }) {
      return state.map((cont) => {
        if (cont.TempId === control.TempId) {
          cont.Conditions.push(condition)
        }
        return cont
      })
    },
    [rawActions.addOptionCondition](state, { payload: { control, option, condition } }) {
      return state.map((cont) => {
        if (cont.TempId === control.TempId) {
          cont.Options.map((opt) => {
            if (opt.Id === option.Id) {
              opt.Conditions.push(condition)
            }
            return opt
          })
        }
        return cont
      })
    },
    [rawActions.setEmailControlCondition](state, { payload: { control, condition, conditionsRecipient } }) {
      return state.map((cont) => {
        if (cont.TempId === control.TempId) {
          cont.Conditions.map((cond) => {
            if (cond.TempId === condition.TempId) {
              cond.ConditionsRecipient = [...conditionsRecipient]
            }
            return cond
          })
        }
        return cont
      })
    },
    [rawActions.setEmailOptionCondition](state, { payload: { control, option, condition, conditionsRecipient } }) {
      return state.map((cont) => {
        if (cont.TempId === control.TempId) {
          cont.Options.map((opt) => {
            if (opt.Id === option.Id) {
              opt.Conditions.map((cond) => {
                if (cond.TempId === condition.TempId) {
                  cond.ConditionsRecipient = [...conditionsRecipient]
                }
                return cond
              })
            }
            return opt
          })
        }
        return cont
      })
    },
    [rawActions.deleteControlCondition](state, { payload: { control, conditionType } }) {
      return state.map((cont) =>
        cont.TempId === control.TempId
          ? { ...cont, Conditions: cont.Conditions.filter((cond) => cond.Type !== conditionType) }
          : cont
      )
    },
    [rawActions.deleteTextControlCondition](state, { payload: { control, conditionType, triggerType } }) {
      const deletedConditionId = state
        .find((cont) => cont.TempId === control.TempId)
        .Conditions.find((cond) => cond.Type === conditionType && cond.TriggerType === triggerType).TempId

      const newState = deletedConditionId
        ? state.map((cont) =>
            cont.TempId === control.TempId
              ? { ...cont, Conditions: cont.Conditions.filter((cond) => cond.TempId !== deletedConditionId) }
              : cont
          )
        : state

      return newState
    },
    [rawActions.deleteOptionCondition](state, { payload: { control, option, conditionType } }) {
      return state.map((cont) => {
        if (cont.TempId === control.TempId) {
          cont.Options = cont.Options.map((opt) =>
            opt.Id === option.Id
              ? { ...opt, Conditions: opt.Conditions.filter((cond) => cond.Type !== conditionType) }
              : opt
          )
        }
        return cont
      })
    },
    [rawActions.saveControlConditionSolution](state, { payload: { control, conditionType, solution } }) {
      return state.map((cont) => {
        if (cont.TempId === control.TempId) {
          cont.Conditions = cont.Conditions.map((cond) =>
            cond.Type === conditionType ? { ...cond, Solution: solution } : cond
          )
        }
        return cont
      })
    },
    [rawActions.saveTextControlConditionSolution](
      state,
      { payload: { control, conditionType, solution, triggerType } }
    ) {
      return state.map((cont) => {
        if (cont.TempId === control.TempId) {
          cont.Conditions = cont.Conditions.map((cond) =>
            cond.Type === conditionType && cond.TriggerType === triggerType ? { ...cond, Solution: solution } : cond
          )
        }
        return cont
      })
    },
    [rawActions.saveOptionConditionSolution](state, { payload: { control, option, conditionType, solution } }) {
      return state.map((cont) => {
        if (cont.TempId === control.TempId) {
          cont.Options.map((opt) => {
            if (opt.Id === option.Id) {
              opt.Conditions = opt.Conditions.map((cond) =>
                cond.Type === conditionType ? { ...cond, Solution: solution } : cond
              )
            }
            return opt
          })
        }
        return cont
      })
    },
    [rawActions.saveConditionTrigger](state, { payload: { control, conditionType, triggerValue, triggerType } }) {
      return state.map((cont) => {
        if (cont.TempId === control.TempId) {
          cont.Conditions = cont.Conditions.map((cond) =>
            cond.Type === conditionType && cond.TriggerType === triggerType
              ? { ...cond, TriggerValue: triggerValue }
              : cond
          )
        }
        return cont
      })
    },
    [rawActions.saveInfoDescription](state, { payload: { control, infoDescription } }) {
      return state.map((cont) => {
        if (cont.TempId === control.TempId) {
          cont.Info.Description = infoDescription
        }
        return cont
      })
    },
    [rawActions.toggleControlInfo](state, { payload }) {
      const control = payload
      const defaultInfo = {
        Type: ContentType.Unknown,
        Url: null,
        FileName: null,
        Description: null,
        ControlLabel: control.Label,
      }

      return state.map((cont) => {
        if (cont.TempId === control.TempId) {
          if (!cont.Info) cont.Info = defaultInfo
          else cont.Info = null
        }
        return cont
      })
    },
    [rawActions.setInfoContent](state, { payload: { control, infoContent } }) {
      return state.map((cont) => {
        if (cont.TempId === control.TempId) {
          cont.Info.Type = infoContent.Type
          cont.Info.Url = infoContent.Url
          cont.Info.FileName = infoContent.FileName
        }
        return cont
      })
    },
    [rawActions.clearInfoContent](state, { payload }) {
      const control = payload

      return state.map((cont) => {
        if (cont.TempId === control.TempId) {
          cont.Info.Type = ContentType.Unknown
          cont.Info.Url = null
          cont.Info.FileName = null
        }
        return cont
      })
    },
    [rawActions.saveControlOptionName](state, { payload: { control, option, newOptionName } }) {
      return state.map((cont) => {
        if (cont.TempId === control.TempId) {
          cont.Options.map((opt) => {
            if (opt.Id === option.Id) {
              opt.Value = newOptionName
            }
            return opt
          })
        }
        return cont
      })
    },
    [rawActions.addControlOption](state, { payload }) {
      const control = payload
      const newOption = createOption(control)
      return state.map((cont) => {
        if (cont.TempId === control.TempId) {
          cont.Options.push(newOption)
        }
        return cont
      })
    },
    [rawActions.deleteControlOption](state, { payload: { control, option } }) {
      const newState = state.map((cont) =>
        cont.TempId === control.TempId ? { ...cont, Options: cont.Options.filter((opt) => opt.Id !== option.Id) } : cont
      )

      const updatedList = _updateOrder(newState, control)
      return updatedList
    },
    [rawActions.updateQuestionType](state, { payload: { control, type, prevType } }) {
      const newState = state.map((cont) =>
        cont.TempId === control.TempId
          ? {
              ...cont,
              Type: type,
              Conditions:
                prevType === FormControlType.BodyTemperature && cont.Conditions
                  ? cont.Conditions.filter((cond) => cond.TriggerType !== ConditionTriggerTypes.UnansweredQuestions)
                  : cont.Conditions,
            }
          : cont
      )

      return newState
    },
    [rawActions.changeDateControlType](state, { payload }) {
      const control = payload
      const newType = control.ControlType === ControlType.Date ? ControlType.DateTime : ControlType.Date
      const newState = state.map((cont) => (cont.TempId === control.TempId ? { ...cont, ControlType: newType } : cont))

      return newState
    },
    [rawActions.toggleProcessingByVqaModel](state, { payload: { control, processByVQAModel } }) {
      const newState = state.map((cont) =>
        cont.TempId === control.TempId ? { ...cont, ProcessByVQAModel: processByVQAModel } : cont
      )

      return newState
    },
    [rawActions.updateFormActionWorkflow](state, { payload: { control, workflowId } }) {
      return state.map((cont) => (cont.TempId === control.TempId ? { ...cont, WorkflowId: workflowId } : cont))
    },
    [rawActions.addFormControlAttribute](state, { payload: { control, attribute } }) {
      return state.map((cont) => {
        if (cont.TempId === control.TempId) {
          cont.Attributes.push(attribute)
        }
        return cont
      })
    },
    [rawActions.deleteFormControlAttribute](state, { payload: { control, attributeName } }) {
      return state.map((cont) =>
        cont.TempId === control.TempId
          ? { ...cont, Attributes: cont.Attributes.filter((att) => att.Name !== attributeName) }
          : cont
      )
    },
    [rawActions.resetFormBuilderState](state, action) {
      return defaultState
    },
  },
  defaultState
)
