import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import { Box, withStyles, Link } from '@material-ui/core'

import { appConfigStateSelectors } from '@tabeeb/modules/appConfigState'

import ErrorMessage from '../errorMessage'

import styles from './styles'

const PageLayout = ({
  classes,
  form,
  controls,
  links,
  bottomLinks,
  bannerUrl,
  bannerLink,
  backgroundColor,
  backgroundImageUrl,
  panelColor,
  textColor,
  error,
}) => {
  const bannerImage = <img className={classes.banner} alt='banner' src={bannerUrl} />

  return (
    <Box
      className={classes.page}
      style={{
        color: textColor,
        backgroundColor,
        backgroundImage: backgroundImageUrl ? `url(${backgroundImageUrl})` : null,
      }}
    >
      <Box className={classes.panel}>
        <Box className={classes.panelBackground} style={{ background: panelColor }} />
        {bannerLink ? (
          <Link className={classes.bannerLink} href={bannerLink} target='_blank' title={`Proceed to ${bannerLink}`}>
            {bannerImage}
          </Link>
        ) : (
          bannerImage
        )}
        <Box className={classes.formContainer}>
          <ErrorMessage errorMessage={error} />
          {form}
          {controls}
          <Box className={classes.linksContainer}>{links}</Box>
        </Box>
        <Box className={classes.bottomLinksContainer}>{bottomLinks}</Box>
      </Box>
    </Box>
  )
}

PageLayout.defaultProps = {
  controls: null,
  bottomLinks: null,
}

PageLayout.propTypes = {
  classes: PropTypes.shape({
    page: PropTypes.string.isRequired,
    panel: PropTypes.string.isRequired,
    banner: PropTypes.string.isRequired,
    bannerLink: PropTypes.string.isRequired,
    bottomLinksContainer: PropTypes.string.isRequired,
    bullet: PropTypes.string.isRequired,
    bulletIcon: PropTypes.string.isRequired,
    formContainer: PropTypes.string.isRequired,
    linksContainer: PropTypes.string.isRequired,
    panelBackground: PropTypes.string.isRequired,
  }).isRequired,
  error: PropTypes.string,
  form: PropTypes.object.isRequired,
  links: PropTypes.object.isRequired,
  bottomLinks: PropTypes.object.isRequired,
  panelColor: PropTypes.string,
  controls: PropTypes.object,
  textColor: PropTypes.string,
  bannerUrl: PropTypes.string,
  bannerLink: PropTypes.string,
  backgroundColor: PropTypes.string,
  backgroundImageUrl: PropTypes.string,
}

const mapStateToProps = (state) => {
  return {
    textColor: appConfigStateSelectors.getLoginPageTextColor(state),
    panelColor: appConfigStateSelectors.getLoginBoudingBoxBackgroundColor(state),
    backgroundColor: appConfigStateSelectors.getLoginPageBackgroundColor(state),
    backgroundImageUrl: appConfigStateSelectors.getLoginBackgroundImage(state),
    bannerUrl: appConfigStateSelectors.getBannerUrl(state),
    bannerLink: appConfigStateSelectors.getBannerLink(state),
  }
}

export default withRouter(withStyles(styles)(connect(mapStateToProps)(PageLayout)))
